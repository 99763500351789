// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-index-js": () => import("./../../../src/templates/about-page/index.js" /* webpackChunkName: "component---src-templates-about-page-index-js" */),
  "component---src-templates-blog-list-index-js": () => import("./../../../src/templates/blog-list/index.js" /* webpackChunkName: "component---src-templates-blog-list-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/blog-post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-contact-page-index-js": () => import("./../../../src/templates/contact-page/index.js" /* webpackChunkName: "component---src-templates-contact-page-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-projects-page-index-js": () => import("./../../../src/templates/projects-page/index.js" /* webpackChunkName: "component---src-templates-projects-page-index-js" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

